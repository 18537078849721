// config.js

const bizIcon = 'https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/qyQmQfbHZp7dDY3w7N4g/media/6754445229695ac7186f1c38.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/qyQmQfbHZp7dDY3w7N4g/media/254bea3b-c1de-49b5-9705-c65f41fee82f.jpeg";
const imageTwo = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/qyQmQfbHZp7dDY3w7N4g/media/ba1ada74-17c8-4ade-a0f1-4c681d2dcdc0.jpeg";
const imageThree = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/qyQmQfbHZp7dDY3w7N4g/media/040dae1d-578f-4292-b10d-ddd309dd2a08.jpeg";
const imageFour = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/qyQmQfbHZp7dDY3w7N4g/media/dc08fb73-160a-4afd-9b96-08ebb69b2b6e.jpeg";
const imageFive = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/qyQmQfbHZp7dDY3w7N4g/media/63248258-be65-4e22-ba3c-b6fcb17591dd.jpeg";

const redirectLink = "https://masorcaconstruction.com/";

export const siteConfig = {
  title: "Mas & Orca Construction",
  metaDescription: "Mas & Orca Construction",
  theme: {
    primaryColor: "red-600",
    secondaryColor: "gray-900",
    accentColor: "text-red-600"
  },
  logo: {
    src: bizIcon,
    alt: "Mas & Orca Construction",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Mas & Orca Construction",
    headline: "Quality You Can Trust in Philadelphia, PA",
    address: "1831 E Madison St, Philadelphia, Pennsylvania, 19134, United States",
    description: `
      Mas & Orca Construction is a trusted name in the construction industry, based in the heart of Philadelphia, PA. With over 2 years of experience, we pride ourselves on delivering top-quality services to every client. From residential to commercial projects, our team is committed to excellence, ensuring every project is completed to the highest standards. When you choose Mas & Orca Construction, you're choosing quality, reliability, and unparalleled craftsmanship.
    `,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Quality You Can Trust in Philadelphia, PA",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Expert Craftsmanship: Specializing in custom construction solutions, including framing, carpentry, and finishing work, to bring your vision to life with precision and quality.",
    },
    {
        description: 
          "High-Quality Materials: We utilize premium materials, including hardwoods, engineered wood, and durable finishes, ensuring every project exceeds expectations in durability and aesthetics.",
    },
    {
        description: 
          "Client-Centered Approach: With over 2 years of experience and 350+ satisfied clients, our team ensures clear communication, timely delivery, and personalized service for every project.",
    },
  ],
  services: {
    sectionTitle: "Our Construction Services",
    description: 
      "Mas & Orca Construction provides expert craftsmanship and reliable construction services for residential and commercial projects in Philadelphia, PA. From custom carpentry to detailed trim work, we deliver quality you can trust.",
    callouts: [
      {
        name: 'Carpentry',
        description: 
          "Our skilled team specializes in precise carpentry, offering tailored solutions for framing, woodwork, and structural enhancements for both homes and businesses.",
        imageSrc: imageTwo,
        imageAlt: 'Professional carpenter working on a custom framing project.',
      },
      {
        name: 'Custom Cabinetry',
        description: 
          "We design and create high-quality custom cabinetry that perfectly fits your space and style, enhancing functionality and aesthetics in kitchens, offices, and storage areas.",
        imageSrc: imageThree,
        imageAlt: 'Custom cabinetry installation in a modern kitchen.',
      },
      {
        name: 'Trim Work',
        description: 
          "Elevate your property with our detailed trim work, including crown molding, baseboards, and decorative woodwork that adds elegance and refinement to any space.",
        imageSrc: imageFour,
        imageAlt: 'Detailed trim work being installed in a residential living room.',
      },
    ]
  },
  about: {
    sectionTitle: "About Us",
    description:   
      "Mas & Orca Construction is your trusted partner for construction and carpentry services, proudly serving Philadelphia, King of Prussia, Wilmington, Trenton, and surrounding areas. With over 2 years of experience, our skilled team is dedicated to delivering exceptional craftsmanship and reliable solutions for both residential and commercial projects. Whether it’s a new build, renovation, or custom carpentry, we prioritize attention to detail, professionalism, and customer satisfaction to transform your vision into reality.",
    image: {
      src: imageFive,
      alt: "Mas & Orca Construction team delivering exceptional construction services in Philadelphia, PA.",
      width: 1200,
      height: 800,
    },
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "1831 E Madison St, Philadelphia, Pennsylvania, 19134, United States",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.5193923319834!2d-75.11559478464863!3d39.98624927941627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c8774e78e67f%3A0xabcdef123456789!2s1831%20E%20Madison%20St%2C%20Philadelphia%2C%20PA%2019134%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=1831%20E%20Madison%20St,%20Philadelphia,%20PA,%2019134',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=1831+E+Madison+St,+Philadelphia,+PA+19134&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/1831+E+Madison+St,+Philadelphia,+PA+19134/@39.986249,-75.115595,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/1831+E+Madison+St,+Philadelphia,+PA+19134/@39.986249,-75.115595,15z',
      },
    ],  
  },
  contact: {
    sectionTitle: "Contact Mas & Orca Construction",
    description: 
      "Elevate your space with Mas & Orca Construction. Whether you're planning a new build, renovation, or custom carpentry project, our skilled team is here to bring your vision to life. Contact us today for expert guidance and a free estimate tailored to your needs.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (267) 528-8430",
    ],
    logo: bizIcon,
    emailRecipient: "info@masorcaconstruction.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


